import React, { useState, useRef, useLayoutEffect } from 'react';
import { deviceType } from "@cargo/common/helpers";

export const ContextSubMenu = (props) => {

	const {label, icon, className, children, ...otherProps} = props;

	// For Submen visibility.
	const [subMenuVisible, setSubMenuVisible] = useState(false);

	const submenuLayer = props.layer ? props.layer : 1;

	const isTouch = deviceType() === 'touch';
	let enterEvent = isTouch ? 'pointerdown' : 'pointerenter';
	let leaveEvent = isTouch ? 'pointerup' : 'pointerleave';

	const toggleSubmenu = ( e ) => { 

		if (e.type !== enterEvent && e.type !== leaveEvent) {
			// console.log('get out', e.type, enterEvent, leaveEvent)
			// e.preventDefault();
			return;
		}

		// console.log('do stuff', e.type)
		
		handleMenuLabel(e, 'label');
		// Get the data attribute layer and convert it to a number
		let targetLayer = e.target.closest('.sub-menu-title');
		targetLayer = targetLayer ? targetLayer.getAttribute('layer') : null;
		targetLayer = targetLayer ? parseInt( targetLayer ) : null;
		// If the pointer is leaving the sub menu and the submenu layer is less than or equal to the submenu layer.
		if( e.type == leaveEvent && e.target.closest('.sub-menu') && targetLayer >= submenuLayer ){
			// Add a one time event listener to the entire context menu for mouse over.
			document.querySelector('.context-menu').addEventListener('mouseover', (e) => {

				let currentEventTargetLayer = e.target.closest('.sub-menu-title');
				currentEventTargetLayer = currentEventTargetLayer ? currentEventTargetLayer.getAttribute('layer') : null;
				currentEventTargetLayer = currentEventTargetLayer ? parseInt( currentEventTargetLayer ) : null;
				// If we're mousing back into the context menu or one on a higher layer, do nothing
				if( e.target.closest('.sub-menu') && currentEventTargetLayer >= submenuLayer ){
					return
				}
				// If we're mousing into a context menu on a lower layer, close all those on a higher layer.
				setSubMenuVisible( false );
				// do more stuff...
			}, { once: true });

			return
		}

		var targetIsSubmenuLabel = e.target.classList.contains('sub-menu-label') ||
		( e.target.classList.contains('before') && e.target.nextElementSibling?.classList.contains('sub-menu-label') )||
		( e.target.classList.contains('after') && e.target.previousElementSibling?.classList.contains('sub-menu-label') ) 

		if( e.type == enterEvent && e.target.closest('.sub-menu') && !targetIsSubmenuLabel ){
			return
		}

		// mouse enter for non-touch devices
		// show the menu
		if( e.type == enterEvent && e.target.closest('.sub-menu-title') ){
			if( isTouch && subMenuVisible ){
				setSubMenuVisible( false );
				return
			}
			// console.log('pointer enter, show menu')
			setSubMenuVisible( true );
			return
		}

		// release mouse on non-touch devices
		// hide the menu
		if( !isTouch && e.type == leaveEvent && e.target.closest('.sub-menu-title') ){
			setSubMenuVisible( false );
			return
		}

		// release touch on touch devices
		// show the menu
		// if ( isTouch && e.type == leaveEvent && e.target.closest('.sub-menu-title')) {
		// 	setSubMenuVisible( !subMenuVisible );
		// 	return
		// }

		// touch device pointerdown
		// do nothing

		// console.log('??, toggle value', !subMenuVisible)
		// This will PROBABLY cause a bug...
		// setSubMenuVisible(!subMenuVisible);
	}

	// For Submenu size.
	const subMenuRef = useRef();
	const labelRef = useRef();

	const [submenuHovered, setSubmenuHovered] = useState(false);
	const [submenuLabelHovered, setSubmenuLabelHovered] = useState(false);
	const [submenuPosition, setSubmenuPosition] = useState({ x: null });

	const handleHover = (e) => {
		handleMenuLabel(e, 'menu');
		if(e.type == enterEvent) {
			setSubmenuHovered(true)
		}

		if(e.type == leaveEvent){
			setSubmenuHovered(false);
		}
	}

	const handleMenuLabel = (e, source) => {

		if( e.type == enterEvent && source == 'label' ){
			setSubmenuLabelHovered( true );
			return
		}

		if( e.type == enterEvent && source == 'menu' ){
			setSubmenuLabelHovered( false );
			return
		}

		if( e.type == leaveEvent ){
			setSubmenuLabelHovered( false );
			return
		}

	}
	// Submenu positioning
	useLayoutEffect(() => {
		if (subMenuRef.current) {
			// props.getdimensions() <-- this is how it should be done, but this data is always 1 render behind/late
			let windowWidth  = document.documentElement.clientWidth;
			let windowHeight = document.documentElement.clientHeight;
			let parentDimensions = subMenuRef.current.closest('.context-menu').getBoundingClientRect();
			if( props.layer >= 2 ){
				let previousLayer = props.layer - 1;
				let menuParent = subMenuRef.current.closest('[layer="'+previousLayer+'"] > .sub-menu');
				parentDimensions = menuParent.getBoundingClientRect();
			}
			
			let rect = subMenuRef?.current?.getBoundingClientRect();
			let offset = labelRef?.current?.offsetTop;

			let x = null;
			let side = 'left';
			let vertical = 'top'

			x = parentDimensions.width - 6;

			// Drop down from label
			if( parentDimensions.x + parentDimensions.width + rect.width > windowWidth - 15  ){
				x = x + 1;
				side = 'right'
			}

			// take remaining viewport space / mid align with label
			if( parentDimensions.y + offset + rect.height > windowHeight - 5 ){
				let newTop = parentDimensions.y + offset; // top of label in viewport
				newTop = newTop + rect.height // bottom of element in viewport 
				let remainder = newTop - windowHeight; // space left over
				offset = offset - remainder;
			}

			setSubmenuPosition({
				[side]     : x-1+'px',
				[vertical] : offset+'px'
			});
		}
	},[subMenuVisible]);

	return (
		<div 
			className={`sub-menu-title ${subMenuVisible ? ('open') : ('')} ${submenuHovered ? ('hover'):('')}`}
			layer={ submenuLayer }
			onPointerEnter={ toggleSubmenu }
			onPointerLeave={ toggleSubmenu }
			onPointerDown={ toggleSubmenu }
			onPointerUp={ toggleSubmenu }
			style={{ zIndex: 10+submenuLayer }}

		>
			<span className={`before ${submenuLabelHovered ? ('label-hovered'):('')}`}></span>
			<div
				className={`sub-menu-label ${submenuLabelHovered ? ('label-hovered'):('')}`}
				ref={labelRef}
			>
				{label}
			</div>
			<span className={`after${submenuLabelHovered ? (' label-hovered'):('')}${icon ? ' icon' : ''}`}>
				{icon ? icon : null}
			</span>

			{subMenuVisible ? ( 
			<div 
				className={`sub-menu`}
				ref  = { subMenuRef }
				style= { submenuPosition }
				onPointerEnter={ handleHover }
				onPointerLeave={ handleHover }
			>
				<div className="list">
					{ children }
				</div>
			</div>
			 ) : ( null )}
		</div>
	);

};