import React, { useState, useEffect } from 'react';

export const TextReveal = ({
    texts = ["Cargo", "Site Builder"],
    endIcon = null,
    loop = false,
    revealDelay = 30, // Can be a number or an array [num1, num2]: num1 is the revealDelay of another TextReveal instance, num2 is the number of characters revealed in that instance. This matches the completion time.
    breakDelay = 1400,
    initialDelay = 800,
    customClass = null,
    reRender = false,
    appearAsText = false, // If true, the text will not animate. Used when animation has played and we don't want it to repeat every render.
    onMouseEnter,
    onMouseLeave
}) => {
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [currentLetterIndex, setCurrentLetterIndex] = useState(-1);
    const [start, setStart] = useState(false);
    const [showEndIcon, setShowEndIcon] = useState(false);
    const [revealDelayFactor, setRevealDelayFactor] = useState(typeof revealDelay === 'number' ? revealDelay : revealDelay[0] / revealDelay[1]);
    const [prevReRender, setPrevReRender] = useState(reRender);

    useEffect(() => {
        if (appearAsText) {
            setRevealDelayFactor(0); // No reveal delay
            breakDelay = 0; // No break delay
            initialDelay = 0; // No initial delay
            loop = false; // No loop
            setCurrentTextIndex(texts.length - 1);
            setCurrentLetterIndex(texts[texts.length - 1].length - 1);
            setShowEndIcon(true);
        }
    }, []);

    useEffect(() => {
        // If reRender value changes from false to true...
        if (reRender !== prevReRender && reRender === true) {
            // Reset the text reveal animation.
            setCurrentTextIndex(0);
            setCurrentLetterIndex(-1);
            setStart(false);
            setShowEndIcon(false);
            // Activate timeout to re-render the text reveal animation.
            const reRenderTimeout = setTimeout(() => {
                setStart(true);
            }, initialDelay);
            // Clear the timeout if the component unmounts.
            return () => clearTimeout(reRenderTimeout);
        }
        // Update the previous reRender value.
        setPrevReRender(reRender);
    }, [reRender]);

    // If revealDelay is an array, calculate revealDelayFactor
    useEffect(() => {
        // Check if revealDelay is an array and calculate revealDelayFactor
        if (Array.isArray(revealDelay) && revealDelay.length === 2) {
            let [delay, letters] = revealDelay;
            if (endIcon) {
                letters += 1;
            }
            const currentTextLength = texts[currentTextIndex].length;
            // Calculate the delay factor based on the current text length
            const calculatedDelay = (delay / currentTextLength) * letters;
            setRevealDelayFactor(calculatedDelay);
        } else {
            setRevealDelayFactor(revealDelay);
        }
    }, [texts, currentTextIndex, revealDelay, endIcon]);

    // Trigger display of the end icon when the last letter of the last text is reached
    useEffect(() => {
        if (currentTextIndex === texts.length - 1 && currentLetterIndex === texts[texts.length - 1].length - 1 && endIcon) {
            const timeoutId = setTimeout(() => {
                setShowEndIcon(true);
            }, revealDelayFactor);
        }
    }, [currentTextIndex, currentLetterIndex, texts, endIcon, revealDelayFactor]);

    // Start text reveal animation
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setStart(true);
        }, initialDelay);

        return () => clearTimeout(timeoutId);
    }, [initialDelay]);

    // Loop (unused)
    useEffect(() => {
        if (!start) return;
        if (!loop && currentTextIndex === texts.length - 1 && currentLetterIndex === texts[currentTextIndex].length - 1) return;

        if (currentLetterIndex < texts[currentTextIndex].length - 1) {
            const intervalId = setInterval(() => {
                setCurrentLetterIndex((prevIndex) => prevIndex + 1);
            }, revealDelayFactor);
            return () => clearInterval(intervalId);
        }

        const timeoutId = setTimeout(() => {
            setCurrentTextIndex((prevIndex) => {
                if (prevIndex < texts.length - 1) {
                    return prevIndex + 1;
                } else if (loop) {
                    return 0;
                }
                return prevIndex;
            });
            setCurrentLetterIndex(-1);
        }, breakDelay);

        return () => clearTimeout(timeoutId);
    }, [texts, revealDelay, breakDelay, loop, currentTextIndex, currentLetterIndex, start]);

    return (
        <>
            {texts.map((text, index) => (
                <span
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    key={index} 
                    className={`text-reveal${customClass ? ` ${customClass}` : ''}`}
                    style={{ display: index === currentTextIndex ? 'inline' : 'none' }}
                >
                    {text.split('').map((letter, letterIndex) => (
                        <span
                            key={letterIndex}
                            style={{ visibility: letterIndex <= currentLetterIndex && index === currentTextIndex ? 'visible' : 'hidden' }}
                        >
                            {letter}
                        </span>
                    ))}
                    {index === texts.length - 1 && endIcon && (
                        <span
                            key="endIcon"
                            className={"end-icon"}
                            style={{ visibility: showEndIcon ? 'visible' : 'hidden' }}
                        >
                            {endIcon}
                        </span>
                    )}
                </span>
            ))}
        </>
    );
};